/* eslint no-restricted-syntax: 0 */
import { parse, format } from 'url';

import { Cookies } from '@confluence/cookies';

export const FF_BASED_BRANCH_VERSION = 'confluence.frontend.branch.deployment';
export const BRANCH_VERSION = 'useFrontendBranch';
export const BUILD_VERSION = 'useFrontendBuildVersion';

export const exitBranchFn = () => {
	const urlComponents = parse(window.location.href, true);

	window.location.assign(
		format({
			path: urlComponents.path,
			query: {
				...urlComponents.query,
				[BRANCH_VERSION]: 'off',
				[BUILD_VERSION]: 'off',
			},
			hash: urlComponents.hash,
		}),
	);
};

export const isBranchDeployFn = (featureFlags) => {
	const ffBasedBranchVersion = featureFlags[FF_BASED_BRANCH_VERSION];
	let cookieBasedBranchVersion;

	if (window.SSR_RENDER_PARAMS__) {
		cookieBasedBranchVersion = window.__SSR_RENDER_PARAMS__.useFrontendBranch;
	} else {
		try {
			cookieBasedBranchVersion = Cookies.get(BRANCH_VERSION);
		} catch (err) {
			// Likely a firefox edge-case: "Error: can't access dead object"
			// Occasionally, document.cookie is cleaned up despite living
			// references -- including js-cookie's
			cookieBasedBranchVersion = 'off';
		}
	}

	return !!(
		(cookieBasedBranchVersion && cookieBasedBranchVersion !== 'off') ||
		(ffBasedBranchVersion && ffBasedBranchVersion !== 'off')
	);
};
